import React, { lazy, Suspense } from "react";

import { Placeholder, Slider } from ".";

import "../assets/scss/components/_reviews.scss";

const Review = lazy(() => import("./Review"));

const DEFAULT_REVIEWS = [
	{
		rating: 5,
		title: "Lovely flower arrangement",
		comments: "Lovely flower arrangement, lasted much longer than any shop bought flowers I’ve purchased. Arrived quickly.",
		reviewer: {
			first_name: "N",
			last_name: "Gwen"
		},
		store_review_id: "20763195"
	},
	{
		rating: 5,
		title: "beautiful flowers",
		comments: "beautiful flowers, always fresh and always delivered on the day ordered.",
		reviewer: {
			first_name: "W",
			last_name: "Milner"
		},
		store_review_id: "20762987"
	},
	{
		rating: 5,
		title: "Fantastic discovery and beautiful bouquets",
		comments: "I discovered 123 flowers just in time for my mum's birthday! Fantastic discovery and beautiful bouquets at wonderful prices.",
		reviewer: {
			first_name: "S",
			last_name: "Thomas"
		},
		store_review_id: "20756174"
	},
	{
		rating: 5,
		title: "your Service is excellent",
		comments: "I am a Regular customer I use 123 Flowers quite often & your Service is excellent & I thank you for your lovely flower arrangements 💐💐",
		reviewer: {
			first_name: "L",
			last_name: "Moorhead"
		},
		store_review_id: "20737670"
	}
];

export const ReviewSection = ({ slides_per_row = {}, reviews = [] }) => {
	const filteredWords = ["awful", "avoid"];

	const filteredReviews = (reviews.length ? reviews : DEFAULT_REVIEWS).filter((review) => {
		const reviewText = review.comments?.toLowerCase() || ""; // Safely handle comments field
		return !filteredWords.some((word) => reviewText.includes(word));
	});

	return (
		<Suspense
			fallback={
				<Placeholder
					style={{
						height: 300
					}}
				/>
			}
		>
			<section className="reviews-section">
				<div className="row no-wrap align-items-stretch">
					<Slider
						slides={filteredReviews.map((review, index) => {
							// Destructure with defaults for safety
							const { rating = 0, title = "No Title", comments = "No comments available.", reviewer = { first_name: "Anonymous", last_name: "" }, store_review_id = "" } = review;

							// Safely handle the reviewer's full name
							const reviewerName = `${reviewer.first_name || "Anonymous"} ${reviewer.last_name || ""}`.trim();

							// Safely handle link generation (if store_review_id is missing, link won't break)
							const reviewLink = store_review_id ? `https://www.reviews.io/company-review/store/123-flowers-co-uk/${store_review_id}` : "#";

							return {
								element: (
									<Suspense
										fallback={
											<Placeholder
												style={{
													height: 100
												}}
											/>
										}
									>
										<Review
											key={index}
											rating={rating}
											title={title}
											text={comments}
											reviewer={reviewerName}
											link={reviewLink}
										/>
									</Suspense>
								)
							};
						})}
						prefix="review-slider"
						arrows={false}
						slides_per_row={slides_per_row}
						draggable={true}
					/>
				</div>
			</section>
		</Suspense>
	);
};
