import { ApiRequest } from "./base";

const apiRoute = "search";

export const SearchApi = {
	async Search(query) {
		return ApiRequest(`${apiRoute}/${query}`, "GET");
	},
	async InstantSearch(query) {
		return ApiRequest(`${apiRoute}/instant/${query}`, "GET");
	}
};
