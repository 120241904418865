import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../stores/AppStore";
import { PaymentApi } from "../api";
import Config from "../Config";

const { PAYPAL_CLIENT_ID } = Config;

import "../assets/scss/components/_paypal.scss";

export const PayPal = () => {
	const navigate = useNavigate();

	const onPaymentCompleted = () => {
		if (useAppStore.getState().isDebug) {
			console.log(`PayPal.jsx - Payment success`);
		}

		navigate("/cart/payment-success");
	};
	return (
		<div className="paypal-buttons">
			<hr />

			<PayPalScriptProvider
				options={{
					"client-id": PAYPAL_CLIENT_ID,
					enableFunding: [FUNDING.PAYLATER],
					disableFunding: [FUNDING.CARD, FUNDING.SOFORT],
					// buyerCountry: "GB", // can't be used in prod
					currency: "GBP",
					commit: true
				}}
			>
				<PayPalButtons
					style={{
						layout: "vertical",
						fundingicons: "true",
						color: "blue",
						shape: "rect",
						size: "responsive",
						disableMaxWidth: "true",
						zIndex: 10
					}}
					createOrder={async () => {
						const orderId = await PaymentApi.PaypalSaveClient();
						return orderId;
					}}
					onApprove={async () => {
						const response = await PaymentApi.PaypalDoSale();
						if (response.success) {
							onPaymentCompleted();
						} else {
							// Handle error
							console.error(`PayPal.jsx - Payment failed`);
						}
					}}
				/>
			</PayPalScriptProvider>
		</div>
	);
};
