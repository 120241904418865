import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartEmpty } from "@fortawesome/pro-regular-svg-icons";
import { faStar, faHeart } from "@fortawesome/pro-solid-svg-icons";

import "../assets/scss/components/_action-buttons.scss";

import { useUserStore } from "../stores/UserStore";
import { Link } from "react-router-dom";

export const ActionButtons = ({ pos = "left", rating = 0, product_id = 0, buyButton = false, product_link = "" }) => {
	const { WishlistAdd, WishlistRemove, wishlistInfo, userInfo } = useUserStore(({ WishlistAdd, WishlistRemove, wishlistInfo, userInfo }) => {
		return { WishlistAdd, WishlistRemove, wishlistInfo, userInfo };
	});

	const WishlistToggle = () => {
		if (wishlistInfo[product_id]) {
			WishlistRemove({ product_id: product_id });
		} else {
			WishlistAdd({ product_id: product_id });
		}
	};

	return (
		<div className={`action-buttons ${pos}`}>
			{buyButton && (
				<Link
					className="btn add-to-cart-button"
					to={product_link}
				>
					Shop Now
				</Link>
			)}
			{rating !== 0 && 1 === 2 && (
				<div className="review-rating">
					<FontAwesomeIcon icon={faStar} /> {rating.toFixed(2)}
					<span>/5</span>
				</div>
			)}
			{userInfo.id && (
				<div className="wishlist-button-container">
					<div
						className={`wishlist-icon ${wishlistInfo[product_id] ? "wishlisted" : ""}`}
						onClick={() => WishlistToggle()}
					>
						<FontAwesomeIcon icon={wishlistInfo[product_id] ? faHeart : faHeartEmpty} />
					</div>
				</div>
			)}
		</div>
	);
};
