import React, { useState, useMemo, useEffect } from "react";

import { DateInfo } from "../utilities/DateUtils";

import { useProductStore } from "../stores/ProductStore";

import "../assets/scss/components/_date-selector.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";

import DatePicker from "react-datepicker";

import { DeliveryOptions } from "./DeliveryOptions";

export const DateSelector = () => {
	const [firstChange, setFirstChange] = useState(true);

	const [customDate, setCustomDate] = useState(false);
	const [usingCustomDate, setUsingCustomDate] = useState(false);
	const [datepickerShown, setDatePickerShown] = useState(false);

	const { selectedDeliveryDate, SetSelectedDeliveryDate, info } = useProductStore((state) => state);

	const { upcoming_dates, disabled_dates } = info?.delivery ?? { upcoming_dates: [], disabled_dates: [] };

	const activeDate = useMemo(() => new Date(info?.delivery?.upcoming_dates[0] ?? new Date()), [info?.delivery?.upcoming_dates]);

	useEffect(() => {
		SetSelectedDeliveryDate(activeDate);
	}, [SetSelectedDeliveryDate, activeDate]);

	const ChangeDeliveryDate = (date) => {
		SetSelectedDeliveryDate(date);
	};

	let selected = firstChange ? activeDate : selectedDeliveryDate;
	let selectedFormatted = DateInfo(selected, false);

	return (
		<section className="date-selector">
			<div className="date-options">
				{upcoming_dates.map((date, i) => {
					const formatted = DateInfo(date, false);

					let callout = "Selected";
					callout = i == 0 && firstChange ? "Quickest" : callout;
					callout = `${formatted.pretty.short.month}${formatted.pretty.short.date}` == "Feb14" ? "Valentine's" : callout;

					return i >= 8 ? null : (
						<div
							key={i}
							className={`date-select ${new Date(selected).getTime() == new Date(date).getTime() ? "active" : ""} ${formatted.pretty.short.month}${formatted.pretty.short.date}`}
							onClick={() => {
								setFirstChange(false);
								ChangeDeliveryDate(date);
								setUsingCustomDate(false);
								setCustomDate(false);
							}}
						>
							<div className="check">
								<FontAwesomeIcon icon={faCheck} />
							</div>

							<div className="day">{formatted.pretty.short.day}</div>
							<div className="date">{formatted.pretty.short.date.length === 1 ? `0${formatted.pretty.short.date}` : formatted.pretty.short.date}</div>
							<div className="month">{formatted.pretty.short.month}</div>
							<div className="delivery-cost">Free Delivery</div>
							<div className="callout">{callout}</div>
						</div>
					);
				})}
			</div>

			{upcoming_dates.length > 8 && (
				<div
					className="show-datepicker"
					onClick={() => {
						setCustomDate(true);
						setDatePickerShown(!datepickerShown);
					}}
				>
					<FontAwesomeIcon icon={faCalendar} /> Schedule your delivery for a later date
				</div>
			)}

			{customDate && (
				<div className="datepicker">
					{usingCustomDate && (
						<div className="datepicker-choice">
							<div className={`date-select ${usingCustomDate ? "active" : ""} ${selectedFormatted.pretty.short.month}${selectedFormatted.pretty.short.date}`}>
								<div className="check">
									<FontAwesomeIcon icon={faCheck} />
								</div>

								<div className="day">{selectedFormatted.pretty.short.day}</div>
								<div className="date">{selectedFormatted.pretty.short.date.length === 1 ? `0${selectedFormatted.pretty.short.date}` : selectedFormatted.pretty.short.date}</div>
								<div className="month">{selectedFormatted.pretty.short.month}</div>
								<div className="delivery-cost">Free Delivery</div>
								<div className="callout">Selected</div>
							</div>

							<div
								className="change-choice"
								onClick={() => {
									setDatePickerShown(true);
								}}
							>
								<FontAwesomeIcon icon={faCalendar} />
								<span className="change-text">Change Date</span>
							</div>
						</div>
					)}

					{datepickerShown && (
						<div className="datepicker-container">
							<DatePicker
								selected={firstChange ? activeDate : selectedDeliveryDate}
								onChange={(date) => {
									setFirstChange(false);
									setUsingCustomDate(true);
									ChangeDeliveryDate(date);
									setDatePickerShown(false);
								}}
								includeDates={info.delivery.upcoming_dates.map((date) => new Date(date))}
								excludeDates={info.delivery.disabled_dates.map((date) => new Date(date))}
								inline
							/>
						</div>
					)}
				</div>
			)}

			<div className="delivery-options-container">
				<strong className="delivery-expected">
					Delivery expected on {selectedFormatted.pretty.long.date} {selectedFormatted.pretty.long.month}
				</strong>
				<span className="delivered-by">via Royal Mail</span>

				<div className="delivery-options-wrapper">
					<DeliveryOptions />
				</div>
			</div>
		</section>
	);
};

export default DateSelector;
