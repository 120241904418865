import React from "react";

import "../assets/scss/components/_message-card-animation.scss";

const tempImages = [
	{
		image: "https://media.bloomandwild.com/v1/320x0/filters:format(webp):quality(75)/https://assets-0.bloomandwild.com/addon-main/lets-get-merry/537b8212-e7cb-4f04-97a6-7ff455dc33be.jpg",
		text: "Christmas Cards"
	},
	{
		image: "https://media.bloomandwild.com/v1/320x0/filters:format(webp):quality(75)/https://assets-0.bloomandwild.com/addon-main/happy-christmas-flam-tier2/fb48c14d-458d-42d6-9949-9faf02cc3d14.jpg",
		text: "Birthday Cards"
	},
	{
		image: "https://media.bloomandwild.com/v1/320x0/filters:format(webp):quality(75)/https://assets-0.bloomandwild.com/addon-main/plain-christmas-card-flamingo-addon/5fc23106-7665-456e-a914-72aa4acab5b3.jpg",
		text: "Sympathy Cards"
	},
	{
		image: "https://media.bloomandwild.com/v1/320x0/filters:format(webp):quality(75)/https://assets-0.bloomandwild.com/addon-main/falalala-day-flam/ae36e1f4-abe2-4a29-a12e-ae7e835357b7.jpg",
		text: "Thank You Cards"
	},
	{
		image: "https://media.bloomandwild.com/v1/320x0/filters:format(webp):quality(75)/https://assets-0.bloomandwild.com/addon-main/limited-edition-liberty-christmas-card-flam/7647b684-29f1-495a-8913-eb9cceb7c4a1.jpg",
		text: "Get Well Soon Cards"
	},
	{
		image: "https://media.bloomandwild.com/v1/320x0/filters:format(webp):quality(75)/https://assets-0.bloomandwild.com/addon-main/woolies-flam/6ae33df9-8524-43aa-87af-c1a9f0bf93d0.jpg",
		text: "Photo Upload Cards"
	}
];

const images = tempImages;

export const MessageCardAnimation = () => {
	return (
		<div className="message-card-images">
			<div className="images-container">
				{images.map((image, i) => {
					return (
						<div
							key={i}
							className="image-container"
						>
							<img
								src={image.image}
								alt="Message Card"
							/>
							<span>{image.text}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};
