import React, { useRef, useEffect, useState, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Image } from "./Image";

import "../assets/scss/components/_slider.scss";

export const Slider = ({ slides, prefix = "slider", slides_per_row = { base: 2, md: 5 }, slide_duration = 5, arrows = false, indicators = false, autoplay = false, draggable = false, fade = false, lightbox = false, indicatorPreview = false }) => {
	const firstSlide = useRef(null);
	const [slidesPerRow, setSlidesPerRow] = useState(slides_per_row);
	const [sliderInformation, setSliderInformation] = useState({
		currentBreakpoint: "base",
		currentSlide: 0,
		slideWidth: 0,
		arrowStatus: false,
		autoplayStatus: false,
		indicatorStatus: false,
		indicatorPreview: indicatorPreview,
		draggableStatus: false,
		draggingInformation: {
			isDragging: false,
			isTouch: false,
			startDragPosition: 0,
			currentDragPosition: 0,
			currentSlide: 0,
			sliderPosition: 0
		}
	});

	useEffect(() => {
		const firstSlideElement = firstSlide.current;
		if (firstSlideElement) {
			const resizeObserver = new ResizeObserver((entries) => {
				for (let entry of entries) {
					const { width } = entry.contentRect;
					setSliderInformation((prev) => ({ ...prev, slideWidth: width }));
				}
			});
			resizeObserver.observe(firstSlideElement);
			return () => resizeObserver.unobserve(firstSlideElement);
		}
	}, []);

	useEffect(() => {
		const getCurrentBreakpoint = () => {
			if (typeof slidesPerRow == "number") {
				setSlidesPerRow({ base: slidesPerRow }); //if only one value is passed, set it to base
			}

			let slidePerRow = "base";
			slidePerRow = slidesPerRow.md && window.innerWidth > 768 ? "md" : slidePerRow;
			slidePerRow = slidesPerRow.lg && window.innerWidth > 950 ? "lg" : slidePerRow;

			return slidePerRow;
		};

		const updateSliderInformation = () => {
			const sliderInformationSettings = {};

			const breakpoint = getCurrentBreakpoint();
			sliderInformationSettings.currentBreakpoint = breakpoint;

			if (slides.length > slidesPerRow[breakpoint]) {
				sliderInformationSettings.arrowStatus = arrows ?? false;
				sliderInformationSettings.autoplayStatus = autoplay ?? false;
				sliderInformationSettings.indicatorStatus = indicators ?? false;
				sliderInformationSettings.draggableStatus = draggable ?? false;
			} else {
				sliderInformationSettings.arrowStatus = false;
				sliderInformationSettings.autoplayStatus = false;
				sliderInformationSettings.indicatorStatus = false;
				sliderInformationSettings.draggableStatus = false;
			}

			setSliderInformation((prev) => ({
				...prev,
				...sliderInformationSettings
			}));
		};

		window.addEventListener("resize", () => {
			updateSliderInformation(); //run on screen size change
		});
		updateSliderInformation(); //run on load
	}, [slidesPerRow, slides.length, arrows, autoplay, indicators, draggable]);

	useEffect(() => {
		setSlidesPerRow(slides_per_row);
	}, [slides_per_row]);

	useEffect(() => {
		if (!sliderInformation.autoplayStatus) return;

		const sliderAutoplay = setInterval(() => {
			setSliderInformation((prev) => ({
				...prev,
				currentSlide: prev.currentSlide + 1
			}));
		}, slide_duration * 1000);

		return () => clearInterval(sliderAutoplay);
	}, [sliderInformation.autoplayStatus, slide_duration]);

	useEffect(() => {
		const MoveSlide = () => {
			let currentSlide = sliderInformation.currentSlide;
			let maxSlides = slides.length - 1;

			currentSlide = currentSlide > maxSlides ? 0 : currentSlide;
			currentSlide = currentSlide < 0 ? maxSlides : currentSlide;

			setSliderInformation((prev) => ({
				...prev,
				currentSlide: currentSlide
			}));
		};

		MoveSlide();
	}, [sliderInformation.currentSlide, sliderInformation.currentBreakpoint, slides.length, slidesPerRow]);

	const MoveSliderTouch = useCallback(
		(e) => {
			const currentDragPosition = e.touches[0].clientX;

			setSliderInformation((prev) => ({
				...prev,
				draggingInformation: {
					...prev.draggingInformation,
					currentDragPosition: currentDragPosition
				}
			}));

			const direction = e.touches[0].clientX > sliderInformation.draggingInformation.startDragPosition ? 1 : -1;

			const distance = sliderInformation.draggingInformation.startDragPosition - currentDragPosition;

			if ((distance < -100 && direction === 1) || (distance > 100 && direction === -1)) {
				setSliderInformation((prev) => ({
					...prev,
					draggingInformation: {
						...prev.draggingInformation,
						sliderPosition: snapToSlide(direction)
					}
				}));
			}
		},

		[sliderInformation.currentBreakpoint, sliderInformation.draggingInformation.sliderPosition, sliderInformation.draggingInformation.startDragPosition, slides.length, slidesPerRow]
	);

	const snapToSlide = (direction) => {
		const slideWidth = firstSlide.current.offsetWidth;
		let newSlide = sliderInformation.draggingInformation.currentSlide + direction;

		newSlide = newSlide > (slides.length - 1) * -1 ? newSlide : (slides.length - 1) * -1;
		newSlide = newSlide > 0 ? 0 : newSlide;

		const pos = slideWidth * newSlide;

		setSliderInformation((prev) => ({
			...prev,
			draggingInformation: {
				...prev.draggingInformation,
				currentSlide: newSlide,
				sliderPosition: pos
			}
		}));

		return pos;
	};

	const MoveSlider = useCallback(
		(e) => {
			if (sliderInformation.draggingInformation.isTouch) return MoveSliderTouch(e);

			const currentDragPosition = e.clientX;

			setSliderInformation((prev) => ({
				...prev,
				draggingInformation: {
					...prev.draggingInformation,
					currentDragPosition: currentDragPosition
				}
			}));

			const direction = e.movementX > 0 ? 1 : -1;
			const distance = sliderInformation.draggingInformation.startDragPosition - currentDragPosition;

			if ((distance < -100 && direction === 1) || (distance > 100 && direction === -1)) {
				setSliderInformation((prev) => ({
					...prev,
					draggingInformation: {
						...prev.draggingInformation,
						sliderPosition: snapToSlide(direction)
					}
				}));
			}
		},
		[MoveSliderTouch, sliderInformation.currentBreakpoint, sliderInformation.draggingInformation.sliderPosition, slides.length, slidesPerRow, sliderInformation.draggingInformation.isTouch]
	);

	let hoverTimer;

	const HandleLightboxStart = (e) => {
		if (!lightbox) return;
		if (window.innerWidth < 768) return;

		clearTimeout(hoverTimer);

		const image = e.target.closest("img");
		if (!image) return;

		const src = image.src;
		image.setAttribute("originalSrc", src);

		if (!src) return;
		const newUrl = src.replace(/w_\d+/, "w_1000");

		image.src = newUrl;
	};

	const HandleLightboxMove = (e) => {
		if (!lightbox) return;
		if (window.innerWidth < 768) return;

		const image = e.target.closest("img");
		if (!image) return;

		const slide = e.target.closest(".slider-item");
		if (!slide) return;

		const slideRect = slide.getBoundingClientRect();
		const scale = 3;

		const cursorXPosition = e.clientX - slideRect.left;
		const cursorYPosition = e.clientY - slideRect.top;

		const imageWidth = slideRect.width * scale;
		const imageHeight = slideRect.height * scale;

		const maxXOffset = (imageWidth - slideRect.width) / 2;
		const maxYOffset = (imageHeight - slideRect.height) / 2;

		const leftOffset = maxXOffset - (cursorXPosition / slideRect.width) * 2 * maxXOffset;
		const topOffset = maxYOffset - (cursorYPosition / slideRect.height) * 2 * maxYOffset;

		const leftPixels = leftOffset + "px";
		const topPixels = topOffset + "px";
		image.style.left = leftPixels;
		image.style.top = topPixels;
	};

	const HandleLightboxStop = () => {
		if (!lightbox) return;
		if (window.innerWidth < 768) return;

		clearTimeout(hoverTimer);

		const images = document.querySelectorAll(".slider-item img");

		images.forEach((image) => {
			const src = image.getAttribute("originalSrc");
			if (src) image.src = src;
			image.removeAttribute("originalSrc");
			image.style.left = "0";
			image.style.top = "0";
		});
	};

	return (
		<div className={`slider ${prefix !== "slider" && `${prefix}`} ${fade ? "fade" : "swipe"} ${lightbox ? "lightbox" : ""}`}>
			<div className={`slider-wrapper no-wrap row align-items-center col-fluid ${prefix !== "slider" ? `${prefix}-wrapper` : ""} ${draggable ? "draggable" : ""}`}>
				{sliderInformation.arrowStatus && (
					<div className={`slider-arrow-container row align-items-stretch justify-content-space-between col-fluid ${prefix !== "slider" && `${prefix}-arrow-container`}`}>
						<div
							className={`slider-arrow slider-arrow--left ${prefix !== "slider" && `${prefix}-arrow ${prefix}-arrow--left`}`}
							onClick={() =>
								setSliderInformation((prev) => {
									return { ...prev, currentSlide: prev.currentSlide - 1 };
								})
							}
						>
							<FontAwesomeIcon icon={faArrowLeft} />
						</div>

						<div
							className={`slider-arrow slider-arrow--right ${prefix !== "slider" && `${prefix}-arrow ${prefix}-arrow--right`}`}
							onClick={() =>
								setSliderInformation((prev) => {
									return { ...prev, currentSlide: prev.currentSlide + 1 };
								})
							}
						>
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
					</div>
				)}

				{/* <div
					className="info"
					style={{
						position: "fixed",
						left: 0,
						background: "black",
						color: "white",
						padding: "10px"
					}}
				>
					<div className="line">{`Current Breakpoint: ${sliderInformation.currentBreakpoint}`}</div>
					<div className="line">{`Slide Width: ${sliderInformation.slideWidth}`}</div>
					<div className="line">{`Draggable: ${sliderInformation.draggableStatus}`}</div>
					<div className="line">{`Start Drag Position: ${sliderInformation.draggingInformation.startDragPosition}`}</div>
					<div className="line">{`Drag Position: ${sliderInformation.draggingInformation.currentDragPosition}`}</div>
					<div className="line">{`Slider Position: ${sliderInformation.draggingInformation.sliderPosition}`}</div>
					<div className="line">{`Current Slide: ${sliderInformation.draggingInformation.currentSlide}`}</div>
				</div> */}

				<div className={`slider-container ${prefix !== "slider" && `${prefix}-container`} ${lightbox ? "lightbox-active" : ""}`}>
					<div
						className={`slider-track row no-wrap ${prefix !== "slider" && `${prefix}-track`}`}
						style={{
							left: sliderInformation.draggableStatus ? sliderInformation.draggingInformation.sliderPosition : sliderInformation.currentSlide * (100 / slidesPerRow[sliderInformation.currentBreakpoint]) * -1 + "%",
							"--slides-per-row": slidesPerRow[sliderInformation.currentBreakpoint]
						}}
						onMouseDown={(e) => {
							if (!sliderInformation.draggableStatus) return;

							setSliderInformation((prev) => ({ ...prev, draggingInformation: { ...prev.draggingInformation, isDragging: true, startDragPosition: e.clientX } }));
						}}
						onTouchStart={(e) => {
							if (!sliderInformation.draggableStatus) return;
							setSliderInformation((prev) => ({ ...prev, draggingInformation: { ...prev.draggingInformation, isDragging: true, isTouch: true, startDragPosition: e.touches[0].clientX } }));
						}}
						onMouseUp={() => {
							setSliderInformation((prev) => ({ ...prev, draggingInformation: { ...prev.draggingInformation, isDragging: false, dragDirection: 0 } }));
						}}
						onMouseLeave={() => {
							setSliderInformation((prev) => ({ ...prev, draggingInformation: { ...prev.draggingInformation, isDragging: false, dragDirection: 0 } }));
						}}
						onTouchEnd={() => {
							setSliderInformation((prev) => ({ ...prev, draggingInformation: { ...prev.draggingInformation, isDragging: false, isTouch: false, dragDirection: 0 } }));
						}}
						onMouseMove={(e) => {
							if (sliderInformation.draggingInformation.isDragging) {
								MoveSlider(e);
							}
						}}
						onTouchMove={(e) => {
							if (sliderInformation.draggingInformation.isTouch) {
								MoveSlider(e);
							}
						}}
					>
						{slides &&
							slides.map((slide, index) => {
								return (
									<div
										key={index}
										className={`slider-item row column ${prefix !== "slider" && `${prefix}-item`} ${slidesPerRow[sliderInformation.currentBreakpoint] === 1 && sliderInformation.currentSlide === index ? "active" : ""}`}
										ref={index === 0 ? firstSlide : null}
										onMouseEnter={(e) => {
											if (lightbox) {
												HandleLightboxStart(e);
											}
										}}
										onMouseMove={(e) => {
											if (lightbox) {
												HandleLightboxMove(e);
											}
										}}
										onMouseLeave={() => {
											if (lightbox) {
												HandleLightboxStop();
											}
										}}
									>
										{slide.image && (
											<Image
												src={slide.image}
												alt={slide.title !== undefined ? slide.title : "Image"}
												className={`slider-item__img col-fluid ${prefix !== "slider" && `${prefix}-item__img`}`}
												width={slide.imageWidth ?? false}
											/>
										)}

										{slide.element && slide.element}

										{slide.show_text && (
											<div className={`${prefix}-item__text ${prefix !== "slider" && `${prefix}-item__text`}`}>
												{slide.title}
												<div className="btn view-range-button">
													View Range <FontAwesomeIcon icon={faArrowRight} />
												</div>
											</div>
										)}
									</div>
								);
							})}
					</div>
				</div>
			</div>

			{sliderInformation.indicatorStatus && (
				<div className={`slider-indicators-container row align-items-center justify-content-center col-fluid ${prefix !== "slider" && `${prefix}-indicators-container`} ${sliderInformation.indicatorPreview && `slider-indicators-preview-container ${prefix}-indicators-preview-container`}`}>
					{[...Array(Math.ceil(slides.length / slidesPerRow[sliderInformation.currentBreakpoint]))].map((slide, index) => {
						return sliderInformation.indicatorPreview ? (
							<div
								key={index}
								className={`slider-indicator slider-indicator-preview ${prefix !== "slider" && `${prefix}-indicator`}` + (index === sliderInformation.currentSlide ? " active" : "")}
								onClick={() => setSliderInformation((prev) => ({ ...prev, currentSlide: index }))}
							>
								{slides[index].image && (
									<Image
										src={slides[index].image}
										alt={slides[index].title !== undefined ? slides[index].title : "Image"}
										className={`slider-item__img col-fluid ${prefix !== "slider" && `${prefix}-item__img`}`}
										width={75}
									/>
								)}
							</div>
						) : (
							<div
								key={index}
								className={`slider-indicator ${prefix !== "slider" && `${prefix}-indicator`}` + (index === sliderInformation.currentSlide ? " active" : "")}
								onClick={() => setSliderInformation((prev) => ({ ...prev, currentSlide: index }))}
							></div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Slider;
