import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useUrlStorage } from "../stores/UrlStore";

import OccasionsImage from "../assets/images/occasion-image.jpg";
import PlantImage from "../assets/images/plant-image.webp";
import NextDayImage from "../assets/images/luxury-image.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import { Image } from "./Image";

import "../assets/scss/components/_navigation-links.scss";

export const NavigationLinks = ({ ...props }) => {
	const { navigationLinks, GetNavigationLinks } = useUrlStorage(({ navigationLinks, GetNavigationLinks }) => ({ navigationLinks, GetNavigationLinks }));

	const extraInfo = {
		"next-day-flowers": {
			image: NextDayImage,
			description: "Next day flowers delivered with care. Our bouquets are hand-tied and delivered in luxury packaging. We offer next day delivery nationwide."
		},
		plants: {
			image: PlantImage,
			description: "Plants are a great gift for any occasion. We offer a wide range of plants for delivery nationwide. Order online today!"
		},
		"birthday-flowers": {
			image: OccasionsImage,
			title: "Occasions",
			description: "We offer a wide range of flowers for all occasions. From birthdays to anniversaries, we have the perfect bouquet for you."
		}
	};

	const [refreshNavLinks, setRefreshNavLinks] = useState(false);

	useEffect(() => {
		if (refreshNavLinks === true) {
			GetNavigationLinks();
		}
	}, [refreshNavLinks, GetNavigationLinks]);

	const SubMenu = (link) => {
		return (
			link.links && (
				<div className="sub-menu">
					<div className="row">
						<div className={`${props.mobile ? "col-12" : "col-6"}`}>
							{link.links.map((subLink, index) => {
								return (
									<Link
										key={index}
										to={subLink.slug}
										className={subLink.classes ? subLink.classes : ""}
										aria-label={subLink.name}
										title={subLink.name}
									>
										{subLink.name}
									</Link>
								);
							})}
						</div>
						{!props.mobile && extraInfo[link.slug] && (
							<div className="col-6 img-container">
								{extraInfo[link.slug]["image"] && (
									<Image
										src={extraInfo[link.slug]["image"]}
										alt={extraInfo[link.slug]["title"] ?? link.name}
										width="100"
									/>
								)}

								<strong>{extraInfo[link.slug]["title"] ?? link.name}</strong>
								{extraInfo[link.slug] && extraInfo[link.slug]["description"] && <p>{extraInfo[link.slug]["description"]}</p>}
							</div>
						)}
					</div>
				</div>
			)
		);
	};

	if (!navigationLinks) {
		setRefreshNavLinks(true);
	}

	return (
		<>
			{navigationLinks &&
				navigationLinks.map((link, index) => {
					return props.mobile ? (
						<div
							key={index}
							className={`parent-link ${link.classes ? link.classes : ""} ${link.name.toLowerCase().replaceAll(" ", "-")}`}
							onClick={(e) => {
								e.target.classList.toggle("open");
							}}
						>
							<Link
								to={link.slug}
								aria-label={link.name}
								title={link.name}
							>
								{link.name}
							</Link>{" "}
							<span className="open-indicator">{link.links && <FontAwesomeIcon icon={faChevronDown} />}</span>
							{SubMenu(link)}
						</div>
					) : (
						<div
							key={index}
							className={`parent-link ${link.classes ? link.classes : ""} ${link.name.toLowerCase().replaceAll(" ", "-")}`}
						>
							<Link
								to={link.slug}
								aria-label={link.name}
								title={link.name}
							>
								{link.name}
							</Link>
							{SubMenu(link)}
						</div>
					);
				})}
		</>
	);
};
