import React, { Suspense, lazy } from "react";
import "../assets/scss/components/_delivery-section.scss";

import { DeliveryDate } from "./DeliveryDate";
import { DeliveryForm } from "./DeliveryForm";

import { DeliveryFormNew } from "./DeliveryFormNew";

export const DeliverySection = ({ showDeliveryOptions = true, showFullAddress, setShowFullAddress }) => {
	return (
		<section className="delivery-section">
			<Suspense fallback={<div>Loading...</div>}>
				<DeliveryDate showDeliveryOptions={showDeliveryOptions} />
			</Suspense>
			<br />
			<strong className="section-title">Fill in the recipient details:</strong>
			<p>Start adding in the delivery postcode below and we will do the rest.</p>

			<DeliveryFormNew
				showFullAddress={showFullAddress}
				setShowFullAddress={setShowFullAddress}
			/>

			{/* <Suspense fallback={<div>Loading...</div>}>
				<DeliveryForm
					showFullAddress={showFullAddress}
					setShowFullAddress={setShowFullAddress}
				/>
			</Suspense> */}
		</section>
	);
};
