import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faShoppingBasket, faTimes, faHeart, faUser, faBagShopping, faBars } from "@fortawesome/pro-regular-svg-icons";

import { AccountContainer, MiniCart, MiniCartContainer, NavigationLinks, SearchBar, Slider } from ".";

import { useCartStore } from "../stores/CartStore";
import { useAppStore } from "../stores/AppStore";

import { Image } from "./Image";

import "../assets/scss/components/_header.scss";

import { WebsiteBanner } from "./WebsiteBanner";

import { useLocation } from "react-router-dom";

import Config from "../Config";

export const Header = () => {
	const [mobileNav, setMobileNav] = useState(false);

	const location = useLocation();

	const { logo } = Config;

	useEffect(() => {
		setMobileNav(false);
	}, [location]);

	const websiteBanner = useAppStore((state) => state.websiteBanner);

	return (
		<div className="header-container">
			<div className="usp-section">
				{websiteBanner?.status && websiteBanner?.text ? (
					<WebsiteBanner
						link={websiteBanner.link}
						text={websiteBanner.text}
						buttonText={websiteBanner.button_text}
					/>
				) : (
					<Slider
						slides={[
							{
								element: <p>Order by 10pm for next day delivery</p>
							},
							{
								element: <p>Free delivery, 7 days a week</p>
							},
							{
								element: (
									<a
										target="_blank"
										rel="noreferrer"
										aria-label="Our Reviews on Reviews.io"
										href="https://www.reviews.co.uk/company-reviews/store/123-flowers-co-uk"
									>
										12,000+ reviews on Reviews.io <FontAwesomeIcon icon={faArrowRight} />
									</a>
								)
							}
						]}
						slide_duration={5}
						prefix="usp-slider"
						slides_per_row={{
							base: 1
						}}
						autoplay={true}
						fade={true}
					/>
				)}
			</div>
			<div className="top-section container">
				<div className="row align-items-center justify-content-center">
					<div className="burger-toggle col-2">
						<button
							className="hamburger"
							id="mobile-toggle"
							aria-label="Open Menu"
							onClick={() => {
								setMobileNav(!mobileNav);
							}}
						>
							<svg
								className={`hamburger-svg ${mobileNav ? "open" : ""}`}
								viewBox="0 0 100 100"
								width="35"
								height="35"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
							>
								<line
									className="hamburger-line  hamburger-line--1"
									x1="20"
									y1="50"
									x2="80"
									y2="50"
								/>
								<line
									className="hamburger-line  hamburger-line--2"
									x1="20"
									y1="50"
									x2="80"
									y2="50"
								/>
								<line
									className="hamburger-line  hamburger-line--3"
									x1="20"
									y1="50"
									x2="80"
									y2="50"
								/>
							</svg>
						</button>
					</div>
					<Link
						className="logo-container col-2 col-md-2"
						to="/"
						aria-label="Homepage"
						title="Homepage"
					>
						<Image
							src={logo}
							alt="123 Flowers Logo"
							width="80px"
							imported={true}
						/>
					</Link>
					<SearchBar />
					<div
						className="action-buttons col-8 col-md-2"
						style={{
							gap: "30px",
							justifyContent: "flex-end",
							alignItems: "flex-end"
						}}
					>
						<MiniCartContainer />
						{/* <Link
							style={{
								fontWeight: "bold",
								textAlign: "center",
								fontSize: "20px"
							}}
							to="/wishlist"
							aria-label="Wishlist"
							title="Wishlist"
						>
							<FontAwesomeIcon icon={faHeart} />
							<span
								style={{
									display: "block",
									fontSize: "13px"
								}}
							>
								Wishlist
							</span>
						</Link> */}
						<Link
							style={{
								fontWeight: "bold",
								textAlign: "center",
								fontSize: "20px"
							}}
							to="/account"
							aria-label="Account"
							title="Account"
						>
							<FontAwesomeIcon icon={faUser} />
							<span
								style={{
									display: "block",
									fontSize: "13px"
								}}
							>
								Account
							</span>
						</Link>
					</div>
				</div>
			</div>

			<div className={`navigation-bar ${mobileNav ? "open" : ""}`}>
				<div className="container">
					<AccountContainer />
					<SearchBar
						mobile={true}
						instantSearch={false}
					/>
					<div className="row align-items-center justify-content-start">
						<NavigationLinks mobile={window.innerWidth < 768 ? true : false} />
					</div>
				</div>

				<button
					className="close-button"
					onClick={() => {
						setMobileNav(false);
					}}
				>
					<FontAwesomeIcon icon={faTimes} /> Close Menu
				</button>
			</div>
		</div>
	);
};
