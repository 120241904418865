import React from "react";

import "../assets/scss/components/_flower-care-tips.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSunPlantWilt, faDroplet, faLeafHeart } from "@fortawesome/pro-regular-svg-icons";

export const FlowerCareTips = () => {
	return (
		<div className="flower-care-tips-section">
			<div className="row justify-content-space-between">
				<div className="col-12 col-md-4 tip">
					<strong className="tip-title">
						<FontAwesomeIcon icon={faSunPlantWilt} /> Light
					</strong>
					<p>Keep me in a sunny spot, but not in direct sunlight.</p>
				</div>
				<div className="col-12 col-md-4 tip">
					<strong className="tip-title">
						<FontAwesomeIcon icon={faDroplet} /> Water
					</strong>
					<p>Water me every 2-3 days, but be careful not to over water.</p>
				</div>
				<div className="col-12 col-md-4 tip">
					<strong className="tip-title">
						<FontAwesomeIcon icon={faLeafHeart} /> Maintenance
					</strong>
					<p>Trim any leaves which fall below the waterline and keep vase clean.</p>
				</div>
			</div>
		</div>
	);
};
