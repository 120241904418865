import React, { useState, useEffect, useRef } from "react";

import { FullReviewSection } from "./FullReviewSection";
import { Slider } from "./Slider";

import "../assets/scss/components/_home-callout.scss";

export const HomeCallout = () => {
	return (
		<div className="home-callout">
			<div className="why-123">
				<div className="container">
					<h1>Why Choose 123 Flowers?</h1>
					<div className="row align-items-center justify-content-space-between">
						<Slider
							slides={[
								{
									element: (
										<div className="why-point">
											{/* <Image
								src={why1}
								alt="Free Delivery"
							/> */}
											<strong>Free Delivery</strong>
											<p>
												We offer <strong>free delivery</strong> as standard on our orders, leaving the guesswork in the past. The price you see is what you will pay.
											</p>
										</div>
									)
								},
								{
									element: (
										<div className="why-point">
											{/* <Image
								src={why2}
								alt="7 Days a Week"
							/> */}
											<strong>Fair & Ethical Farming Practices</strong>
											<p>In partnership with top-tier flower farms, we undergo rigorous audits to guarantee worker rights, ethical production, and sustainability are consistently upheld as primary requirements.</p>
										</div>
									)
								},
								{
									element: (
										<div className="why-point">
											{/* <Image
								src={why3}
								alt="100% Satisfaction Guarantee"
							/> */}
											<strong>Actively Environmentally Conscious</strong>
											<p>We've eliminated single-use plastics from our products, taken steps to minimize our carbon footprint and are fierce advocates for the Wildflower campaign.</p>
										</div>
									)
								}
							]}
							slides_per_row={{ base: 1, md: 3 }}
							autoplay={true}
							indicators={true}
						/>
					</div>
				</div>
			</div>
			<div className="review-wrapper">
				<div className="container">
					<FullReviewSection slides_per_row={{ base: 1, md: 2.7 }} />
				</div>
			</div>
		</div>
	);
};

export default HomeCallout;
