import React, { useState, useEffect, useMemo } from "react";

import { DateSelector } from "./DateSelector";

export const DeliveryDate = ({ showDeliveryOptions = true }) => {
	return (
		<section className="datepicker-section">
			<strong className="section-title">
				Select your delivery date: <span className="scroll-more">Scroll For More</span>
			</strong>
			<DateSelector />
		</section>
	);
};
