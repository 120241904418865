import React from "react";

import { Map as PigeonMap } from "pigeon-maps";

import "../assets/scss/components/_map.scss";

export const Map = ({ lat, long, height = 200 }) => {
	return (
		<div className="map-container">
			<PigeonMap
				height={height}
				defaultCenter={[54.018105, -2.005736]}
				defaultZoom={5}
				center={[lat, long]}
				zoom={11}
				mouseEvents={false}
				attribution={false}
				touchEvents={false}
			></PigeonMap>
		</div>
	);
};
