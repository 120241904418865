import React, { useEffect, useRef } from "react";

import { useCartStore } from "../stores/CartStore";
import { faShoppingBasket, faBagShopping } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MiniCart } from "./MiniCart";

import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";

export const MiniCartContainer = () => {
	const miniCartRef = useRef(null);
	const { cart, GetCart, ToggleMiniCart, miniCart } = useCartStore(({ cart, GetCart, ToggleMiniCart, miniCart }) => ({ cart, GetCart, ToggleMiniCart, miniCart }));

	useEffect(() => {
		GetCart();
	}, [GetCart]);

	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes("/cart")) {
			ToggleMiniCart(false);
		}
	}, [ToggleMiniCart, location]);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (miniCart === true && miniCartRef.current && !miniCartRef.current.contains(e.target)) {
				ToggleMiniCart(false);
			}
		};

		document.addEventListener("click", handleOutsideClick, true);

		return () => {
			document.removeEventListener("click", handleOutsideClick, true);
		};
	}, [ToggleMiniCart, miniCart]);

	return (
		<div
			className="basket-link mini-cart-container no-style"
			ref={miniCartRef}
			style={{
				fontWeight: "bold",
				fontSize: "20px"
			}}
		>
			<div
				className="basket-link-icon"
				onClick={() => {
					ToggleMiniCart();
				}}
				style={{
					textAlign: "center",
					cursor: "pointer"
				}}
			>
				{cart && cart.length > 0 && <div className="cart-length">{cart.length}</div>}
				<FontAwesomeIcon icon={faBagShopping} />
			</div>
			<span
				style={{
					display: "block",
					fontSize: "13px",
					cursor: "pointer"
				}}
				onClick={() => {
					ToggleMiniCart();
				}}
			>
				Basket
			</span>
			<div className="mini-cart-wrapper">
				{cart && (
					<MiniCart
						status={miniCart}
						cart={cart}
					/>
				)}
			</div>
		</div>
	);
};
