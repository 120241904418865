import React from "react";
import { FlowerCareTips } from "../components/FlowerCareTips";

import "../assets/scss/components/_product-description.scss";

export const ProductDescription = ({ shortDescription, fullDescription, productName = "", id = "", sku = "", productType }) => {
	return (
		<section className="description-section">
			<div className="product-debug-information">{`${id} - ${sku}`}</div>

			<h1 className="product-name">{productName ?? " "}</h1>
			{shortDescription && <strong className="short-description">{shortDescription}</strong>}
			{fullDescription &&
				(fullDescription.includes("</") ? (
					<p
						className="description"
						dangerouslySetInnerHTML={{ __html: fullDescription }}
					></p>
				) : (
					<p className="description">{fullDescription}</p>
				))}
			{productType === "Flowers" && <FlowerCareTips />}
		</section>
	);
};
