import React from "react";

import "../assets/scss/components/_addon-section.scss";
import { useProductStore } from "../stores/ProductStore";

import { Image } from "./Image";
import Config from "../Config";

import Moet from "../assets/images/addons/moet.jpg";

import Slider from "./Slider";
import { useUserStore } from "../stores/UserStore";

export const AddonSection = ({ addedToCart }) => {
	const { userInfo } = useUserStore((state) => state);
	const { liveUrl } = Config;
	const { availableAddons, SetAddons, selectedAddons, includedAddons, addonSort } = useProductStore(({ availableAddons, SetAddons, selectedAddons, includedAddons, addonSort }) => ({ availableAddons, SetAddons, selectedAddons, includedAddons, addonSort }));

	const sortedAddons = Object.keys(availableAddons).sort((a, b) => {
		return addonSort.indexOf(parseInt(a)) - addonSort.indexOf(parseInt(b));
	});

	const temporaryImages = {
		1071: Moet
	};

	const addonsPerSlide = window.innerWidth > 768 ? 10 : 4;

	const addonSlides = sortedAddons.reduce(
		(acc, addonId) => {
			if (includedAddons[addonId] !== undefined) return acc;
			// Check if a new slide should be started
			if (acc[acc.length - 1].length === addonsPerSlide) {
				acc.push([]);
			}

			acc[acc.length - 1].push(addonId);

			return acc;
		},
		[[]]
	); // Initialize with one empty array to handle the first slide

	return availableAddons && includedAddons && Object.keys(availableAddons).length > 0 ? (
		<section className="addon-section">
			<strong className="section-title">Let's make your gift even more special:</strong>

			{includedAddons && Object.keys(includedAddons).length > 0 && (
				<div
					className="included-addons-wrapper row justify-content-space-between"
					style={{
						"--gap": "10px"
					}}
				>
					<strong className="col-12">Included With This Product:</strong>
					{Object.keys(includedAddons).map((addonId) => {
						let addon = includedAddons[addonId];
						addonId = parseInt(addonId);

						return (
							<div
								className="addon included-addon col-12 col-md-4"
								key={addonId}
							>
								<div className="row align-items-center justify-content-space-between">
									<div className="addon-image col-3">
										<Image
											src={temporaryImages[addonId] ?? `${liveUrl}/images/attributes/${includedAddons[addonId].image_name}`}
											alt={addon.pretty_name}
											size="200"
										/>
									</div>
									<div className="addon-info row align-items-start justify-content-space-between col-8">
										<span className="addon-name col-fluid">{addon.pretty_name}</span>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}

			<Slider
				slides={addonSlides.map((slide, index) => {
					return {
						element: (
							<div
								className="addons-wrapper row justify-content-flex-start"
								style={{
									"--gap": "10px"
								}}
							>
								{slide.map((addonId) => {
									let addon = availableAddons[addonId];
									addonId = parseInt(addonId);

									return (
										<div
											className="addon col-6 col-md-5th"
											key={addonId}
											onClick={() => {
												SetAddons(addonId);
											}}
										>
											<div className="addon-image">
												<Image
													src={temporaryImages[addonId] ?? `${liveUrl}/images/attributes/${availableAddons[addonId].image_name}`}
													alt={addon.pretty_name}
													width="200"
												/>

												<div className={`addon-check-status ${selectedAddons.includes(addonId) ? "checked" : ""}`}>{selectedAddons.includes(addonId) ? "Added To Cart" : "Add To Cart"}</div>

												{userInfo.is_staff === "Yes" ? <div className="addon-id">{addonId}</div> : ""}
											</div>
											<div className="addon-info row align-items-start justify-content-space-between">
												<span className="addon-name col-fluid">{addon.pretty_name}</span>
												<p className="addon-price">{parseFloat(addon.price) === 0 ? <strong>FREE!</strong> : `£${parseFloat(addon.price).toFixed(2)}`}</p>
											</div>
										</div>
									);
								})}
							</div>
						)
					};
				})}
				slides_per_row={{
					base: 1,
					md: 1
				}}
				prefix="addons-slider"
				autoplay={false}
				arrows={true}
			/>
		</section>
	) : (
		<></>
	);
};
