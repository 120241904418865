import React, { lazy, Suspense, useEffect } from "react";

import { CalloutBanner } from "../components/CalloutBanner";
import { HomeUpsell } from "../components/HomeUpsell";
import { HomeProducts } from "../components/HomeProducts";

const LiveChat = lazy(() => import("../components/LiveChat"));

export const Home = () => {
	return (
		<div className="home">
			<HomeUpsell />
			<CalloutBanner />
			<HomeProducts />

			<Suspense fallback={null}>
				<LiveChat />
			</Suspense>
		</div>
	);
};

export default Home;
