import React, { useState, useEffect } from "react";
import { format, addDays } from "date-fns";

import "../assets/scss/pages/_product-page.scss";

import { ProductSizes } from "../components/ProductSizes";
import { StepTracker } from "../components/StepTracker";
import { Slider } from "../components/Slider";
import { AddonSection } from "../components/AddonSection";
import { DeliverySection } from "../components/DeliverySection";
import { MessageCardSectionNew } from "../components/MessageCardSectionNew";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { TabbedSection } from "../components/TabbedSection";
import { ActionButtons } from "../components/ActionButtons";
import { RelatedProducts } from "../components/RelatedProducts";
import { ProductChoices } from "../components/ProductChoices";
import { Placeholder } from "../components/Placeholder";
import { AddToCartButton } from "../components/AddToCartButton";
import { LoadingAnimation } from "../components/LoadingAnimation";
import { ProductDescription } from "../components/ProductDescription";
import { AvailableFrom } from "../components/AvailableFrom";
import { MiniCartItem } from "../components/MiniCartItem";
import { ProductImage } from "../components/ProductImage";
import { FullReviewSection } from "../components/FullReviewSection";
import { AddonPopup } from "../components/AddonPopup";

import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useProductStore } from "../stores/ProductStore";

import { useParams } from "react-router-dom";
import { useCartStore } from "../stores/CartStore";
import { useUserStore } from "../stores/UserStore";
import { useMetaStore } from "../stores/MetaStore";

import { useNavigate } from "react-router-dom";

// import TagManager from "react-server-side-gtm";

import { Link } from "react-router-dom";
import { useAppStore } from "../stores/AppStore";
import { PaymentOptions } from "../components/PaymentOptions";
import { min } from "lodash";

window.dataLayer = window.dataLayer || [];

const GA4ProductData = (productInfo) => {
	window.dataLayer.push({
		event: "view_item",
		currency: "GBP",
		ecommerce: {
			detail: {
				products: {
					name: productInfo.products_name,
					id: productInfo.products_id,
					price: productInfo.price_retail,
					category: productInfo.product_type,
					delivery_availability: productInfo.delivery?.lead_time,
					product_group: "base"
				}
			}
		}
	});
};

export const ProductPage = ({ id = "no-id" }) => {
	const params = useParams();
	if (id === "no-id") {
		id = params.id;
	}

	let dummyData = false;

	const [secondStage, setSecondStage] = useState(false);
	const [formFailed, setFormFailed] = useState(false);
	const [showFullAddress, setShowFullAddress] = useState(false);

	const [loaded, setLoaded] = useState(false);

	const [paymentFormShown, setPaymentFormShown] = useState(false);

	const { info, GetInfo, price, SetCurrentProductStep, AddToCart, address, productSteps, relatedProducts, selectedAddons, selectedDeliveryDate, selectedDeliveryOption, selectedSize, messageCardText, messageCardType, originalPrice, msrpPrice, SetAddedToCart, cartId, printzwareId, availableAddons } = useProductStore((state) => state);

	const { cart, totals } = useCartStore(({ cart, totals }) => {
		return { cart, totals };
	});

	const { userInfo } = useUserStore(({ userInfo }) => {
		return { userInfo };
	});

	const { peakConfigs } = useAppStore(({ peakConfigs }) => {
		return { peakConfigs };
	});

	const popupAddonId = "87"; //for some reason keys come back as strings

	const [showAddonPopup, setShowAddonPopup] = useState(false);
	const [popupCompleted, setPopupCompleted] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (id !== undefined && id !== 0) {
			GetInfo(id, true);
		}
	}, [GetInfo, id]);

	useEffect(() => {
		try {
			document.querySelector(".main-content").scrollTo({ top: 0 });
		} catch (error) {
			console.error("Error scrolling to top of .main-content", error);
		}

		try {
			document.querySelector("html").scrollTo({ top: 0 });
		} catch (error) {
			console.error("Error scrolling to top of html", error);
		}
	}, [secondStage]);

	useEffect(() => {
		if (info.id && info.id !== 0) {
			setLoaded(true);
			// useMetaStore.getState().SetTitle(info.products_name);

			const structuredData = {
				"@context": "http://schema.org",
				"@type": "Product",
				sku: info.sku,
				name: info.products_name,
				image: info.images && info.images[0] ? info.images[0].location : "",
				description: info.short_description && info.short_description !== "" ? info.short_description : info.full_description && info.full_description !== "" ? info.full_description : "A beautiful hand tied bouquet",
				brand: {
					"@type": "Brand",
					name: "123 Flowers"
				},
				offers: {
					"@type": "Offer",
					url: info.product_url,
					itemCondition: "https://schema.org/NewCondition",
					availability: "https://schema.org/InStock",
					price: info.price_retail,
					priceCurrency: "GBP",
					priceValidUntil: format(addDays(new Date(), 30), "yyyy-MM-dd"),
					shippingDetails: {
						"@type": "OfferShippingDetails",
						shippingRate: {
							"@type": "MonetaryAmount",
							currency: "GBP",
							value: 0
						},
						deliveryTime: {
							"@type": "ShippingDeliveryTime",
							businessDays: {
								"@type": "OpeningHoursSpecification",
								dayOfWeek: ["https://schema.org/Monday", "https://schema.org/Tuesday", "https://schema.org/Wednesday", "https://schema.org/Thursday", "https://schema.org/Friday", "https://schema.org/Saturday", "https://schema.org/Sunday"]
							},
							cutOffTime: `${info.delivery?.cutoff}:00:00Z`,
							handlingTime: {
								"@type": "QuantitativeValue",
								minValue: 1,
								maxValue: info.delivery?.lead_time,
								unitCode: "DAY"
							},
							transitTime: {
								"@type": "QuantitativeValue",
								minValue: 1,
								maxValue: 2,
								unitCode: "DAY"
							}
						},
						shippingDestination: {
							"@type": "DefinedRegion",
							addressCountry: "GB"
						}
					}
				}
			};

			useMetaStore.getState().SetMeta({
				title: info?.products_name?.trim() || "",
				description: info?.full_description?.trim() || "A beautiful hand tied bouquet",
				image: info?.images?.[0]?.location?.trim() || "",
				keywords: info?.meta?.keywords?.trim() || "",
				h1: info?.meta?.h1_content?.trim() || info?.products_name?.trim() || "",
				h2: info?.meta?.h2_content?.trim() || "",
				footerContent: info?.meta?.footer_content?.trim() || info?.quote?.trim() || info?.short_description?.trim() || info?.full_description?.trim() || "A beautiful hand tied bouquet",
				canonical: info?.product_url?.trim() || "",
				structuredData: structuredData ?? {}
			});
		}
	}, [info, loaded]);

	useEffect(() => {
		if (loaded === true && info) {
			try {
				GA4ProductData(info);
			} catch (error) {
				console.error("Error setting GA4 data", error);
			}
		}
	}, [loaded, info]);

	if (!info) {
		return <LoadingAnimation pageType="Product Page" />;
	}

	let in_stock = true;
	in_stock = info.stock > 0 ? in_stock : false;
	in_stock = info && info.delivery && info.delivery.upcoming_dates && info.delivery.upcoming_dates.length > 0 ? in_stock : false;

	let images = { ...info.images };
	// delete images[0]; //commented out as we want the first image as well until reshoot

	if (useAppStore.getState().isDebug) {
		console.log("ProductPage.jsx - Product info", info);
	}

	images = dummyData
		? {
				0: {
					location: "https://images.prestigeflowers.co.uk/fetch/w_400,e_sharpen:80,q_auto,f_auto,dpr_auto,f_auto/https://www.prestigeflowers.co.uk/images/products/9c88cdaa0d6f23333564826e2ee15e8a.jpg"
				},
				1: {
					location: "https://images.prestigeflowers.co.uk/fetch/w_400,e_sharpen:80,q_auto,f_auto,dpr_auto,f_auto/https://www.prestigeflowers.co.uk/images/products/ed9ad31cb13a9cf9510ab115c05befce.jpg"
				},
				2: {
					location: "https://images.prestigeflowers.co.uk/fetch/w_400,e_sharpen:80,q_auto,f_auto,dpr_auto,f_auto/https://www.prestigeflowers.co.uk/images/products/b71ad05595ee0987863626f812e4699c.jpg"
				}
		  }
		: images;

	const AddonPopupFunction = () => {
		setShowAddonPopup(true);

		return new Promise((resolve) => {
			setPopupCompleted(() => resolve);
		});
	};

	const AddToCartCheck = async (showCart = true) => {
		if (!address) return;

		const fields = ["address_name", "address_postcode", "address_line1", "address_town", "address_county", "address_country"];

		const validChecks = fields.length;
		const validConfirm = fields.filter((field) => address[field] && address[field] !== "").length;

		if (validChecks === validConfirm) {
			setFormFailed(false);

			if (availableAddons && Object.keys(availableAddons).length !== 0 && Object.keys(availableAddons).includes(popupAddonId)) {
				//we have the addon available for purchase
				if (peakConfigs?.VALENTINES && !selectedAddons.includes(popupAddonId)) {
					await AddonPopupFunction();
				}
			}

			AddToCart({ showCart: showCart }).then((r) => {
				SetAddedToCart(r.length - 1);
				if (window.innerWidth <= 768 && showCart) {
					navigate("/cart");
				}
			});

			return true;
		} else {
			setFormFailed(true);
			setShowFullAddress(true);
			document.querySelector(".delivery-section").scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
		}

		return false;
	};

	return (
		loaded === true && (
			<div
				className={`product-page container ${secondStage ? "second-stage" : "first-stage"} ${formFailed && "form-failed"}`}
				id={info.id}
			>
				<div className="row align-items-stretch justify-content-md-space-between justify-content-center">
					<div className={`col-12 ${secondStage ? "col-md-4" : "col-md-6"} description-container`}>
						<>
							{Object.keys(images).length > 0 && (
								<section className="slider-section additional-images-section">
									<Slider
										slides={Object.keys(images).map((image, index) => {
											return { image: images[image].location, imageWidth: 800 };
										})}
										prefix="additional-images"
										arrows={true}
										slides_per_row={secondStage ? { base: 1, md: 1 } : { base: 1, md: Object.keys(images).length === 1 ? 1 : 1.2 }}
										indicators={true}
										indicatorPreview={true}
										lightbox={true}
									/>
								</section>
							)}
							{secondStage && (
								<section className="second-stage-sidebar-content">
									<ProductChoices
										setSecondStage={setSecondStage}
										SetCurrentProductStep={SetCurrentProductStep}
									/>
									<div className="product-totals">
										<h3>
											Total price: <span>£{parseFloat(price).toFixed(2)}</span>
										</h3>
									</div>
								</section>
							)}
						</>
					</div>

					<div className={`col-12 ${secondStage ? "col-md-8" : "col-md-6"} row column justify-content-start details-sidebar`}>
						<div className="details-container row">
							{!secondStage && <Breadcrumbs productName={info.products_name ?? ""} />}
							<div className="product-details col-12">
								{!secondStage ? (
									<>
										<div className="row align-items-center">
											<div className="col-fluid">
												<h1>{info.products_name ?? " "} </h1>
											</div>

											{in_stock && (
												<div className="col mob-only mobile-pricing row justify-content-lg-space-between align-items-end">
													{parseFloat(info.price_msrp) > parseFloat(info.price_retail) && <div className="msrp">£{parseFloat(msrpPrice).toFixed(2)}</div>}
													<div className="retail">£{parseFloat(originalPrice).toFixed(2)}</div>
												</div>
											)}

											{!secondStage && (
												<div className="col">
													<ActionButtons
														pos="right"
														product_id={info.id ?? 0}
														rating={info.review_average ?? 0}
													/>
												</div>
											)}
										</div>

										{info.quote && info.quote !== "" && <p className="quote">{info.quote}</p>}

										{info.has_sizes === "Yes" && in_stock && <ProductSizes />}

										<AddToCartButton
											inStock={in_stock}
											info={info}
											setSecondStage={setSecondStage}
											secondStage={secondStage}
										/>

										<strong className="delivery-from">
											<AvailableFrom date={info.stock > 0 && info.first_available_delivery !== "" ? info.first_available_delivery : false} />
										</strong>

										<ProductDescription
											shortDescription={info.short_description}
											fullDescription={info.full_description}
											id={info.id}
											sku={info.sku}
											productType={info.product_type}
										/>

										<FullReviewSection slides_per_row={{ base: 1.1, md: 1.2 }} />

										<RelatedProducts info={relatedProducts} />
									</>
								) : (
									<>
										{window.innerWidth < 768 && 1 == 2 && (
											<button
												className="btn back-to-product-information"
												onClick={() => {
													setSecondStage(false);
												}}
											>
												<FontAwesomeIcon icon={faArrowLeft} /> Back To Product Information
											</button>
										)}
										{info.has_sizes === "Yes" && in_stock && window.innerWidth < 768 && <ProductSizes />}

										<DeliverySection
											showFullAddress={showFullAddress}
											setShowFullAddress={setShowFullAddress}
										/>

										<MessageCardSectionNew />

										<AddonSection />

										<section className="add-to-cart-section">
											<div className="recap">
												<strong className="section-title">Your final product configuration:</strong>
												<p>For more details please check the sidebar featured to the left.</p>
											</div>

											{formFailed && <p className="form-failed">Please fill in all required fields to add the product to the basket.</p>}

											{((messageCardType === "standard" && messageCardText === "") || messageCardType === "") && <div className="alert error">You are sending your gift without a message card, please add one if this is a mistake or continue.</div>}

											<MiniCartItem
												image={info?.images?.[0]?.location.replace(".local", ".co.uk")}
												productName={info.products_name}
												price={price}
												size={selectedSize}
												deliveryDate={selectedDeliveryDate}
												deliveryAddress={address.address_name && address.address_line1 && address.address_town && address.address_postcode ? `${address.address_name} at ${address.address_line1}, ${address.address_town}, ${address.address_postcode}` : ""}
												deliveryType={selectedDeliveryOption ? selectedDeliveryOption.name.split("-")[0] : ""}
												addons={selectedAddons}
												removeButtonShown={false}
												messageCard={messageCardText}
												personalisedCardID={printzwareId}
											/>

											{Object.keys(cart).length === 0 && !paymentFormShown && (
												<button
													className="buy-now-button emphasis-button"
													onClick={async () => {
														let res = await AddToCartCheck(false);
														if (res === true) {
															setPaymentFormShown(true);
														}
													}}
												>
													Buy Now
												</button>
											)}

											<div className="payment-form">
												{paymentFormShown === "loading" && <LoadingAnimation />}

												{paymentFormShown === true && (
													<PaymentOptions
														cart={cart}
														userInfo={userInfo}
														totals={totals}
													/>
												)}
											</div>

											{paymentFormShown === true && (
												<div className="or">
													<hr />
													OR
													<hr />
												</div>
											)}

											{cartId === false ? (
												<button
													onClick={async () => {
														await AddToCartCheck();
													}}
													className={`add-to-basket ${paymentFormShown ? "faded" : ""} ${Object.keys(cart).length > 0 ? "emphasis-button" : ""}`}
												>
													Add To Basket &amp; Continue Shopping
												</button>
											) : (
												<>
													{Object.keys(cart).length !== 0 && !paymentFormShown && <div className="alert">Your product has been added to the basket now, please double check the information presented above and make any ammendments needed.</div>}

													<Link
														to="/cart"
														className="btn"
													>
														View Your Cart
													</Link>
												</>
											)}
										</section>
									</>
								)}
							</div>

							{1 == 2 && (
								<div className="row product-info justify-content-space-between step-container col-12">
									<div className="col-12 col-md-7 row column justify-content-end">
										<StepTracker
											productSteps={productSteps}
											setSecondStage={setSecondStage}
										/>
									</div>
									{!secondStage && (
										<div className="col-12 col-md-3 row column justify-content-end image-container">
											{dummyData && dummyData === true ? (
												<Placeholder check={null}>
													<img alt="Main Product" />
												</Placeholder>
											) : (
												<Placeholder check={null}>{info.images && <ProductImage image={info?.images[0]?.location} />}</Placeholder>
											)}
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				{peakConfigs.VALENTINES && showAddonPopup && availableAddons && Object.keys(availableAddons).length !== 0 && Object.keys(availableAddons).includes(popupAddonId) && (
					<AddonPopup
						addonId={popupAddonId}
						resolvePromise={popupCompleted}
						setShowAddonPopup={setShowAddonPopup}
					/>
				)}
			</div>
		)
	);
};

export default ProductPage;
