import React, { useEffect, useState, useRef } from "react";

import { useProductStore } from "../stores/ProductStore";
import { CategorySpecialInfo } from "./CategorySpecialInfo";
import { ProductListing } from "./ProductListing";

export const OutputProducts = ({ products = [], seperatorAfter = false, loadingAnimation = false, specialName = "", lazy = false, aboveFold = false, buyButton = false, description = false }) => {
	const [loaded, setLoaded] = useState(false);
	const [categoryProducts, setCategoryProducts] = useState([]); // Use state to store the products so we can force a re-render when the products are loaded
	const lastProductRef = useRef(null);

	const { GetProductInfoBulk } = useProductStore(({ GetProductInfoBulk }) => {
		return { GetProductInfoBulk };
	});

	useEffect(() => {
		if (!products?.length) return;
		const productIds = products.map((product) => product.products_id);
		if (!products || !productIds.length) return;
		const GetCategoryProducts = async (ids) => {
			const res = await GetProductInfoBulk(ids);
			const sortedProducts = productIds.map((id) => res.find((product) => product.products_id === id));
			setCategoryProducts(sortedProducts);
			setLoaded(true);
		};
		GetCategoryProducts(productIds);
	}, [GetProductInfoBulk, products]);

	return categoryProducts.length ? (
		<div className="row justify-content-space-between justify-content-md-start">
			{categoryProducts
				? categoryProducts.map((product, index) => {
						const isLastProduct = index === categoryProducts.length - 1;
						return product?.products_id ? (
							<React.Fragment key={index}>
								<ProductListing
									ref={isLastProduct ? lastProductRef : null}
									key={index}
									sort={index}
									id={product.products_id}
									info={product}
									size={{ base: 6, md: 3 }}
									lazy={aboveFold ? (lazy && index > 3 ? true : false) : lazy}
									buyButton={buyButton}
									description={description}
								/>
								{seperatorAfter !== false && index === seperatorAfter - 1 && <hr />}
							</React.Fragment>
						) : null;
				  })
				: false}
			<CategorySpecialInfo specialName={specialName} />
		</div>
	) : products && products.length ? (
		<div className="row">
			{products.map((product, index) => {
				return (
					<React.Fragment key={index}>
						<ProductListing
							bogus={true}
							id={index}
							size={{ base: 6, md: 3 }}
							sort={index}
							buyButton={buyButton}
							description={description}
						/>
						{seperatorAfter !== false && index === seperatorAfter - 1 && <hr />}
					</React.Fragment>
				);
			})}
		</div>
	) : null;
};
