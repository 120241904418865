import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { UrlApi } from "../api";

const useUrlStorage = create(
	persist(
		devtools((set, get) => ({
			navigationLinks: [],
			navigationLinkUpdate: false,
			async GetUrl(params) {
				const urlInfo = await UrlApi.GetUrl(params);
				return urlInfo;
			},
			async GetNavigationLinks() {
				if (get().navigationLinkUpdate && get().navigationLinkUpdate > Date.now() - 300000) {
					return get().navigationLinks;
				}

				const res = await UrlApi.GetNavigationLinks();
				set({ navigationLinks: res, navigationLinkUpdate: Date.now() });
			}
		})),
		{
			name: "url-storage"
		}
	)
);

export { useUrlStorage };
