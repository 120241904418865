import React from "react";

import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../assets/scss/components/_delivery-options.scss";
import { useProductStore } from "../stores/ProductStore";
import { Placeholder } from "./Placeholder";

import { Spinner } from "./Spinner";

export const DeliveryOptions = () => {
	const { deliveryOptions, SetSelectedDeliveryMethod, selectedDeliveryOption } = useProductStore((state) => state);

	return (
		<React.Suspense fallback={<Spinner />}>
			<div className="delivery-options">
				{Object.keys(deliveryOptions).map((key) => {
					return (
						<div
							key={key}
							className={`delivery-option row align-items-stretch ${selectedDeliveryOption && deliveryOptions[key]["name"] === selectedDeliveryOption["name"] ? "active" : ""}`}
							onClick={() => {
								SetSelectedDeliveryMethod(key);
							}}
						>
							<div
								className="delivery-option-details col-fluid"
								dangerouslySetInnerHTML={{ __html: deliveryOptions[key]["description"] }}
							></div>

							<div className="pricing-information">
								<div className="checkmark">
									<FontAwesomeIcon icon={faCheck} />
								</div>
								<div className="delivery-option-price">{deliveryOptions[key]["cost"] === 0 ? "FREE" : "£" + deliveryOptions[key]["cost"].toFixed(2)}</div>
							</div>
						</div>
					);
				})}
			</div>
		</React.Suspense>
	);
};
