import React from "react";

import "../assets/scss/components/_category-header.scss";

import { Image } from "./Image";

import ReneHeader from "../assets/images/headers/rene-category.jpg";

export const CategoryHeader = ({ specialName }) => {
	switch (specialName) {
		case "rene":
			return (
				<div className="category-header rene-header">
					<Image
						src={ReneHeader}
						alt="Flowers By Rene Header"
					/>
				</div>
			);
		default:
			return null;
	}
};

export default CategoryHeader;
