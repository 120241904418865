import { create } from "zustand";
import { devtools } from "zustand/middleware";

import config from "../Config";

const initialState = {
	title: config.meta.title,
	description: config.meta.description,
	image: "",
	h1: "",
	h2: "",
	footerContent: "",
	keywords: "",
	canonical: "",
	structuredData: {}
};

const useMetaStore = create(
	devtools((set, get) => ({
		...initialState,
		SetTitle: (title) => {
			set({ title: title + " | " + initialState.title });
		},
		SetDescription: (description) => {
			set({ description });
		},
		SetMeta: (meta) => {
			Object.keys(initialState).map((property) => {
				if (meta[property]) {
					// let appendValue = "";
					// if (initialState[property] !== "") {
					// 	appendValue = " | " + initialState[property];
					// }
					// set({ [property]: meta[property] + appendValue });
					set({ [property]: meta[property] });
				} else {
					set({ [property]: initialState[property] });
				}
				return true;
			});
		},
		ResetMeta: () => {
			for (let key in initialState) {
				set({ [key]: initialState[key] });
			}
		}
	}))
);

export { useMetaStore };
