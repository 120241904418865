import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faWarning } from "@fortawesome/pro-regular-svg-icons";

// import "../assets/scss/components/_message-card-section.scss";
import "../assets/scss/components/_message-card-section-new.scss";

import { GenerateImageLink } from "./Image";
import { useProductStore } from "../stores/ProductStore";
import { useCartStore } from "../stores/CartStore";
import Config from "../Config";
import PersonalisedCardImage from "../assets/images/happy-bday-card.jpg";
import { RecordAMessageSection } from "./RecordAMessageSection";
import { DateDifference } from "../utilities/DateUtils";
import { MessageCardAnimation } from "./MessageCardAnimation";
export const MessageCardSectionNew = () => {
	const [charsLeft, setCharsLeft] = useState(200);
	const [isPersonalisedCardModalOpen, setIsPersonalisedCardModalOpen] = useState(false);
	const [messageCardSelected, setMessageCardSelected] = useState(false);

	const { SetMessageCardText, SetMessageCardType, messageCardType, messageCardText, GetAddPersonalisedCardLink, SetPrintzwareId, printzwareId, SetPrice, selectedDeliveryDate, info, videoMsg } = useProductStore(({ SetMessageCardText, SetMessageCardType, messageCardType, messageCardText, GetAddPersonalisedCardLink, SetPrintzwareId, printzwareId, SetPrice, selectedDeliveryDate, info, videoMsg }) => ({
		SetMessageCardText,
		SetMessageCardType,
		messageCardType,
		messageCardText,
		GetAddPersonalisedCardLink,
		SetPrintzwareId,
		printzwareId,
		SetPrice,
		selectedDeliveryDate,
		videoMsg,
		info
	}));

	const { GetCart } = useCartStore((state) => state);

	useEffect(() => {
		GetCart();
	}, [GetCart]);

	const messageCardSection = useRef(null);

	const ChangeCardType = (type, value = false) => {
		if (type === "standard") {
			SetMessageCardType("standard");
			setMessageCardSelected("standard");
			SetPrintzwareId(null);
			if (value !== false) {
				SetMessageCardText(value);
			}
		} else {
			SetMessageCardType("personalised");
			setMessageCardSelected("personalised");
			SetMessageCardText("");
			if (value !== false) {
				SetPrintzwareId(value);
			}
		}

		setIsPersonalisedCardModalOpen(false);
		SetPrice();
	};

	const [openVideoMessage, setOpenVideoMessage] = useState(false);

	let currentTime = new Date();

	let PersonalisedCardCheck = true;
	PersonalisedCardCheck = DateDifference(selectedDeliveryDate, currentTime).days === 0 && currentTime.getHours() >= Config.personalisedCardCutoff ? false : PersonalisedCardCheck; //if tomorrow delivery and after cutoff
	PersonalisedCardCheck = info.distributor !== "julian" ? false : PersonalisedCardCheck; //if julian distributor

	const [personalisedCardLink, setPersonalisedCardLink] = useState(GetAddPersonalisedCardLink());

	return (
		<section
			className="message-card-section"
			ref={messageCardSection}
		>
			<strong className="section-title">Let them know who to thank:</strong>
			<p>A gift is never complete without a personalised message, select your style below and we will include it with your gift.</p>

			<div className="message-card-options">
				<div
					className={`message-card-option ${messageCardType === "standard" ? "active" : ""}`}
					onClick={() => {
						ChangeCardType("standard");
					}}
				>
					<div className="message-card-option-text">
						<strong>Free Message Card</strong>
						<p>All gifts get a free message card included with them. Write your message and we will print it and send it with your item.</p>
					</div>
					<div className="message-check">
						<div className="checkmark">
							<FontAwesomeIcon icon={faCheck} />
						</div>
						<strong className="price">FREE</strong>
					</div>
					{messageCardType === "standard" && (
						<div className="col-12 free-message-card-choice">
							<strong>Free Message Card</strong>
							<p>All gifts come with a free message card, type your message in the field or choose a personalised option below.</p>
							<div className="field">
								<div className="textarea-container">
									<textarea
										type="text"
										id="card-message"
										minLength="0"
										maxLength="200"
										rows="2"
										value={messageCardText}
										onChange={(e) => {
											setCharsLeft(200 - e.target.value.length);
											ChangeCardType("standard", e.target.value);
										}}
										disabled={messageCardType === "personalised"}
									/>
									<label>Card Message:</label>
									<div className="remaining-characters-section">
										<span className="remaining-characters">{charsLeft}</span> Characters Remaining
									</div>
								</div>
							</div>
						</div>
					)}
				</div>

				{PersonalisedCardCheck && (
					<div className={`message-card-option ${messageCardType === "personalised" ? "active" : ""}`}>
						<div
							className="message-card-option-text"
							onClick={() => {
								ChangeCardType("personalised");
							}}
						>
							<strong>Personalised Greeting Card</strong>
							<p>High quality customised A5 greeting card. Choose from one of our designs and then customise it for your lucky recipient.</p>
						</div>
						<div
							className="message-check"
							onClick={() => {
								ChangeCardType("personalised");
							}}
						>
							<div className="checkmark">
								<FontAwesomeIcon icon={faCheck} />
							</div>
							<strong className="price">{info.free_card === true ? "FREE" : "+£" + Config.personalisedCardPrice}</strong>
						</div>

						{messageCardType === "personalised" && (
							<div className="col-12">
								<div className="personalised-card-choice">
									<div className="text-content">
										<strong>Get A Personalised Greeting Card ({info.free_card === true ? "FREE" : "+£" + Config.personalisedCardPrice})</strong>
										<p>High quality customised A5 greeting card. Choose from one of our designs and then customise it for your lucky recipient.</p>

										{!printzwareId ? (
											<MessageCardAnimation />
										) : (
											<div
												className={`personalised-card-cover ${printzwareId ? "card-added" : ""}`}
												style={{ "background-image": `url('${printzwareId ? `${Config.personalisedThumbUrl}/${printzwareId}.jpg` : GenerateImageLink(PersonalisedCardImage)}')` }}
												onClick={() => {
													setIsPersonalisedCardModalOpen(true);
												}}
											></div>
										)}

										<button
											className="get-started"
											onClick={() => {
												console.log("set");
												ChangeCardType("personalised");
												setIsPersonalisedCardModalOpen(true);

												console.log(messageCardType, personalisedCardLink, isPersonalisedCardModalOpen);
											}}
										>
											{!printzwareId ? "Get Started" : "Edit Card"}
										</button>
									</div>
								</div>

								<PersonalisedCardModal
									SetMessageCardType={SetMessageCardType}
									personalisedCardLink={personalisedCardLink}
									isPersonalisedCardModalOpen={isPersonalisedCardModalOpen}
									personalisedCardCallback={(printzwareId, editLink = "") => {
										console.log("callback");

										ChangeCardType("personalised", printzwareId);
										setPersonalisedCardLink(editLink);
									}}
									setIsPersonalisedCardModalOpen={setIsPersonalisedCardModalOpen}
									ChangeCardType={ChangeCardType}
								/>
							</div>
						)}
					</div>
				)}

				{((messageCardSelected === "personalised" && printzwareId) || (messageCardSelected === "standard" && messageCardText !== "")) && (
					<>
						<strong className="also-add"> You can also add: </strong>

						<div className={`message-card-option ${videoMsg || openVideoMessage ? "active" : ""}`}>
							<div
								className="message-card-option-text"
								onClick={() => {
									setOpenVideoMessage(!openVideoMessage);
								}}
							>
								<strong>
									Video / Voice Message <span>New!</span>
								</strong>
								<p>Record a video or audio message to send with your gift. You can record a message up to 30 seconds long and we will include it on your message card.</p>
							</div>
							<div
								className="message-check"
								onClick={() => {
									setOpenVideoMessage(!openVideoMessage);
								}}
							>
								<div className="checkmark">
									<FontAwesomeIcon icon={faCheck} />
								</div>
								<strong className="price">FREE</strong>
							</div>

							{openVideoMessage && <RecordAMessageSection />}
						</div>
					</>
				)}
			</div>

			<div className="message-card-start-container row align-items-stretch justify-content-space-between">
				{PersonalisedCardCheck && 1 === 2 && (
					<div className={`message-card-start personalised-card col-12 ${messageCardSelected ? (messageCardSelected === "personalised" ? "selected col-md-8" : "col-md-4") : "col-md-6"}`}>
						<span className="callout">Popular!</span>
						<h1>Personalised Greeting Card {info.free_card === true ? "FREE" : "+£" + Config.personalisedCardPrice}</h1>
						<p className={`col-md-12 ${messageCardSelected ? (messageCardSelected === "standard" ? "selected col-md-8" : "col-md-4") : "col-md-6"}`}>Make this gift extra special with a high quality customised A5 greeting card. Choose from one of our designs and then customise it for your lucky recipient.</p>

						<div className="personalised-card-option">
							<div className="personalised-card-container">
								<div
									className={`personalised-card-cover ${printzwareId ? "card-added" : ""}`}
									style={{ "--background-image": `url('${printzwareId ? `${Config.personalisedThumbUrl}/${printzwareId}.jpg` : GenerateImageLink(PersonalisedCardImage)}')` }}
									onClick={() => {
										setIsPersonalisedCardModalOpen(true);
									}}
								>
									{!printzwareId && (
										<div className="addition-symbol">
											<FontAwesomeIcon icon={faPlus} />
										</div>
									)}
								</div>
							</div>
							<button
								className="col-12"
								onClick={() => {
									setIsPersonalisedCardModalOpen(true);
								}}
							>
								Customise Card{" "}
							</button>
						</div>

						{(!messageCardSelected || messageCardSelected === "standard") && (
							<button
								className="get-started"
								onClick={() => {
									ChangeCardType("personalised");
								}}
							>
								{messageCardSelected === "standard" ? "Switch To This Card" : "Send With This Card"}
							</button>
						)}
					</div>
				)}

				{1 === 2 && (
					<div className={`message-card-start standard-card col-12 ${messageCardSelected ? (messageCardSelected === "standard" ? "selected col-md-8" : "col-md-4") : "col-md-6"}`}>
						<h1>Included FREE message card</h1>
						<p>What good is a gift if they don't know who sent it! We include a small message card printed with a handwritten effect with all orders, just type out your message and we will do the rest.</p>
						<div className="card-message col-12">
							<div className="field">
								<label>Card Message:</label>

								<div className="textarea-container">
									{messageCardType === "personalised" && (
										<div className="personalised-warning">
											<FontAwesomeIcon icon={faWarning} />
											You have selected a personalised card, if you would like to switch to a standard message card please click below.
											<button
												onClick={() => {
													ChangeCardType("standard");
												}}
											>
												Change to Standard Card
											</button>
										</div>
									)}
									<textarea
										type="text"
										id="card-message"
										maxLength="200"
										rows="4"
										value={messageCardText}
										onChange={(e) => {
											setCharsLeft(200 - e.target.value.length);
											ChangeCardType("standard", e.target.value);
										}}
										disabled={messageCardType === "personalised"}
									/>
									<div className="remaining-characters-section">
										<span className="remaining-characters">{charsLeft}</span> Characters Remaining
									</div>
								</div>
							</div>
						</div>
						{(!messageCardSelected || messageCardSelected === "personalised") && (
							<button
								className="get-started"
								onClick={() => {
									ChangeCardType("standard");
								}}
							>
								{messageCardSelected === "personalised" ? "Switch To This Card" : "Send With This Card"}
							</button>
						)}{" "}
					</div>
				)}
			</div>

			{messageCardSelected && <div className="message-card-options row"></div>}
			<PersonalisedCardModal
				SetMessageCardType={SetMessageCardType}
				personalisedCardLink={personalisedCardLink}
				isPersonalisedCardModalOpen={isPersonalisedCardModalOpen}
				personalisedCardCallback={(printzwareId, editLink = "") => {
					ChangeCardType("personalised", printzwareId);
					setPersonalisedCardLink(editLink);
				}}
				setIsPersonalisedCardModalOpen={setIsPersonalisedCardModalOpen}
				ChangeCardType={ChangeCardType}
			/>
		</section>
	);
};

const PersonalisedCardModal = ({ personalisedCardLink, isPersonalisedCardModalOpen, setIsPersonalisedCardModalOpen, personalisedCardCallback, SetMessageCardType, ChangeCardType }) => {
	const iframeRef = useRef(null);

	const [key, setKey] = useState(0);

	// Reset iframe when link changes
	useEffect(() => {
		if (isPersonalisedCardModalOpen) {
			setKey((prevKey) => prevKey + 1);
		}
	}, [isPersonalisedCardModalOpen]);

	useEffect(() => {
		const handleIframeMessage = (event) => {
			if (!iframeRef.current || event.source !== iframeRef.current.contentWindow) return;
			const data = JSON.parse(event.data);
			if (data.event === "response") {
				// ChangeCardType("personalised", data.printzware_id);
				personalisedCardCallback(data.printzware_id, data.edit_url);
			} else if (data.event === "close") {
				setIsPersonalisedCardModalOpen(false);
			}
		};
		window.addEventListener("message", handleIframeMessage, false);
		return () => {
			window.removeEventListener("message", handleIframeMessage);
		};
	}, []);

	return (
		<div className={`personalised-card-modal ${isPersonalisedCardModalOpen ? "open" : ""}`}>
			<iframe
				key={key}
				title="personalised-card-modal"
				src={personalisedCardLink}
				ref={iframeRef}
			/>
		</div>
	);
};
