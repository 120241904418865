import React from "react";

import "../assets/scss/components/_spinner.scss";

export const Spinner = () => {
	return (
		<div className="spinner-container">
			<div className="spinner-blackout">
				<div className="spinner"></div>
			</div>
		</div>
	);
};
